/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Header from './Header';
import Footer from './Footer';

require('../styles/amp.scss');

const AMPLayout = ({ children, switchLang }) => {
  return (
    <>
      <Header siteTitle="Mexican Timeshare Solutions" />
      <main>{children}</main>
      <Footer switchLang={switchLang} />
    </>
  );
};
export default AMPLayout;
