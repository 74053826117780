import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash.get';

import AMPLayout from '../components/AMPLayout';
import useI18n from '../utils/useI18n';
import SEO from '../components/seo/SEO';
import FeaturedTestimonial from '../components/FeaturedTestimonial';
import ContactButton from '../components/ContactButton';
import '../styles/components/_components.resort.scss';
import useSiteMetadata from '../utils/useSiteMetadata';
import Content from '../components/Content';

const ResortAMP = ({ pageContext: { switchLang }, data: { record }, location }) => {
  const { t } = useI18n();
  const { siteUrl } = useSiteMetadata();
  const {
    Hotel,
    featuredImg,
    childrenHtmlRehype: [{ htmlAst: content }],
  } = record;
  const fields = [
    'legal_name',
    'sales_locations',
    'collections',
    'rental_companies',
    'representatives',
  ];
  return (
    <AMPLayout switchLang={switchLang}>
      <SEO
        title={record.Hotel.title}
        description={record.Hotel.description}
        keywords={record.Hotel.keywords}
        image={featuredImg ? featuredImg.childImageSharp.fixed : null}
        pathname={location.pathname}
        webpage
        breadCrumbs={[
          {
            name: t('menu.blacklist'),
            url: `${siteUrl}/${t('url.blacklist')}`,
          },
          {
            name: record.Hotel.titulo,
            url: `${siteUrl}/${location.pathname}`,
          },
        ]}
        amp
        noCanonical
      />
      <article className="o-wrapper c-resort">
        <h1 className="u-margin-bottom-tiny">{Hotel.titulo}</h1>
        <h2 className="o-callout">{Hotel.subtitulo}</h2>
        <div className="c-resort__pic">
          <amp-img
            src={`${siteUrl}${get(featuredImg, 'childImageSharp.fixed.src')}`}
            width={get(featuredImg, 'childImageSharp.fixed.width')}
            height={get(featuredImg, 'childImageSharp.fixed.height')}
            alt={Hotel.titulo}
            layout="responsive"
          />
        </div>
        <div className="c-resort__content">
          <dl>
            {fields
              .filter((field) => Hotel[field])
              .map((field) => (
                <span key={field}>
                  <dt>{t(`Hotel.attributes.${field}`)}</dt>
                  <dd>{Hotel[field]}</dd>
                </span>
              ))}
          </dl>
          <Content htmlAst={content} />
        </div>
      </article>
      <FeaturedTestimonial />
      <ContactButton section />
    </AMPLayout>
  );
};

export const query = graphql`
  query HotelQueryAmp($id: String!) {
    record: hotel(id: { eq: $id }) {
      Hotel {
        collections
        created
        description
        id
        keywords
        legal_name
        rental_companies
        representatives
        sales_locations
        slug
        slug_es_mx
        slug_en_us
        subtitulo
        title
        titulo
      }
      childrenHtmlRehype {
        htmlAst
      }
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid
          }
          fixed(quality: 80) {
            src
            width
            height
          }
        }
      }
    }
  }
`;

export default ResortAMP;
